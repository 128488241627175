.page {
  width: 100vw;
  min-height: 100vh;
  background: url('../assets/images/lending-bg.png') no-repeat center;
  background-size: cover;
}

.calculator {
  margin: 7vh auto 0 auto;
  padding: 2rem;
  max-width: 1000px;
  width: 70vw;
  background-color: var(--light-accent);
  border: 1px solid var(--dark-accent);
  border-radius: 1.5rem;
  text-align: center;
}

.calculator h1 {
  padding-bottom: 2rem;
}

.content {
  height: 350px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.leftSide {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.sliderWrapper {
  width: 80%;
  margin: 1rem auto;
}

.sliderWrapper h3 {
  padding: 1rem 0;
}

.titleAndInput {
  margin-bottom: 1.7rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.amountInp {
  width: 8rem !important;
  margin-left: 1rem !important;
}
.amountInp > div {
  color: var(--accent) !important;
  font-weight: 600 !important;
  height: 45px;
}
.amountInp > div > fieldset {
  border: 1px solid var(--accent) !important;
}

.rightSide {
  width: 35%;
  height: 100%;
  border-left: 1px solid var(--accent);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ratesWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.rate {
  margin: 1rem 0;
}

.rate p {
  width: 5rem;
  margin: 0.7rem auto 0 auto;
  padding: 0.3rem 0;
  background: transparent;
  border: 1px solid rgba(91, 97, 251, 0.7);
  border-radius: 0.3rem;
  color: rgba(91, 97, 251, 0.7);
  font-weight: 600;
}

.btn {
  width: 240px !important;
  padding: 0.7rem 1.2rem !important;
  background-color: var(--accent) !important;
  border-radius: 0.5rem !important;
  color: var(--light-accent) !important;
  font-weight: 600 !important;
}

.btn:hover,
.btn:active {
  background-color: #3a1fd194 !important;
}

@media only screen and (max-width: 992px) {
  .calculator {
    width: 80vw;
  }
}

@media only screen and (max-width: 768px) {
  .calculator {
    width: 95vw;
  }

  .content {
    height: auto;
    flex-direction: column;
  }

  .leftSide {
    width: 100%;
  }

  .rightSide {
    width: 75%;
    margin: 3rem 0;
    border-left: none;
  }
}
