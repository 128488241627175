:root {
  --white: #f5f6fa;

  --accent: #00b0af;
  --light-accent: #f0fbfb;
  --dark-accent: #4d9999;

  --gold: #cca354;
  --light-gold: #fbfcd4;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: Montserrat, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-tel-input input{
  margin-left: 0.5rem;
}
.react-tel-input .flag-dropdown{
  margin-left: 1rem;
}

.rtl {
  direction: rtl;
}
.rtl .MuiInputLabel-outlined {
  transform: translate(-14px, 20px) scale(1) !important;
}
.rtl .MuiInputLabel-shrink {
  transform-origin: top right !important;
  transform: translate(-14px, -6px) scale(0.75) !important;
}
.rtl .MuiInputLabel-formControl {
  left: unset !important;
  right: 0 !important;
}
.rtl .MuiOutlinedInput-notchedOutline legend {
  text-align: right !important;
}
.rtl .react-tel-input {
  direction: ltr;
}